import OnboardingOption from "./OnboardingOption";
import "./Onboarding.scss";
import ChatBubble from "pages/DashBoard/ChatBubble";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/UserProvider";
import authService from "actions/services/auth.service";
import { paymentPlanEventTracking } from "Utils/WebEngage";
import { Mixpanel } from "Utils/Mixpanel";

function OnboardingPayment() {
  const {user ,setUser} = useContext(UserContext)
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const opt = [
    {
      text: "Full Stack Once off (R11,750)",
      redirect: "https://paystack.com/pay/selfpaced-onceoff-fs",
    },
    {
      text: "Full Stack 2 months (R6500/month)",
      redirect: "https://paystack.com/pay/selfpaced-2-month-fs",
    },
    {
      text: "Get a study loan. Plans from R500/month*",
      // redirect: "https://chats.landbot.io/v3/H-710586-TZA6006OCE2GJZWZ/index.html",
      redirect: "https://www.zaio.io/manati",
    },
  ];

  useEffect(()=>{
    authService.updateUserData();
  },[])

  const handleProceed = async () => {
    try {
      paymentPlanEventTracking({
        plan: paymentPlan?.text,
        price: paymentPlan?.price
      });
      Mixpanel.track("Payment plan selected", {
        coursename: paymentPlan?.text
      })
      if(selectedIndex===2){
        window.open(paymentPlan?.redirect, '_blank');
        // await authService.mondayReference(user?.email, user?.username, user?.phonenumber);
        setSelectedIndex(3);
        await authService.updatePaymentDetails(3);
        
        authService.updateUserData().then((res)=>{
          setUser({...user,...res?.data});
        })
        return;
      }
      window.location.href = paymentPlan?.redirect;
    } catch (error) {
      
    }
  }

  const handleOptionSelect = async (paymentOption, index) => {
    // if (!currentQuestion?.disabledOptionClick) {
    //   clickSfx();
    setSelectedIndex(index);
    setPaymentPlan(paymentOption);
    // }

    // console.log("SELECTED QUIZ OPTION", quizOption, index);

    // //setting up options data to show
    // if (quizOption?.nextstep) {
    //   Mixpanel.track("Text Onboarding Step", {
    //     selectedOption: quizOption.text,
    //   });
    //   console.log(
    //     "nextoptions to show:",
    //     textBasedOnboardingData[quizOption.nextstep]
    //   );
    //   setSelectedOption(quizOption);

    //   if (quizOption?.redirectToCourse) {
    //     setRedirectToCourse(quizOption.redirectToCourse);
    //   }
    // }
    // if (currentQuestion?.lastStep) {
    //   Mixpanel.track("Text Onboarding Time commitment", {
    //     selectedOption: quizOption.text,
    //   });
    //   setSelectedOption(quizOption);
    // }
  };

  return (
    <div className="onb-wrapper">
      <div className="onb-container">
        <div className="onb-body flex flex-col justify-center items-center">
          {selectedIndex!==3 && (
            <>
            <ChatBubble
            title={"Select Payment Plans"}
            text= "I am so excited for you to start your coding journey."
            showLive={false}
          />
          <div className="onb-question">
            {opt &&
              opt.map((option, index) => (
                <OnboardingOption
                  option={option}
                  handleOptionSelect={handleOptionSelect}
                  index={index}
                  selectedIndex={selectedIndex}
                  // fromQuiz={true}
                />
              ))}
          </div>
          <div className="flex">
          <button
            className="btn-main cursor-pointer rounded-md m-2 p-2 onb-footer-complete-btn border-radius-2"
            onClick={handleProceed}
            disabled={selectedIndex===null}
          >
            Proceed
            {/* {customButtonName ? customButtonName : "Continue"} */}
          </button>
          <button
            className="btn-warning cursor-pointer rounded-md m-2 p-2  border-radius-2"
            onClick={()=> {
              window.location.replace('/app/updated-dashboard?skip=true')
            }}
          >
            Skip
          </button>
          </div>
          </>
          )}

          {selectedIndex===3 && <div className="bg-white p-6 rounded-lg flex flex-col justify-center items-center text-center">
            <p className="p-2 font-semibold">Complete the online application within 5 mins. Expect Feedback within 48 hrs on your application.</p>
            <button
            className="btn-main  rounded-md m-2 p-2 onb-footer-complete-btn border-radius-2"
            onClick={()=> {
              Mixpanel.track('Book a consultation',{
                'user clicked finance option': 'Book a consultation'
              })
              window.open('https://calendly.com/d/cpdj-3h5-2tn/zaio-fullstack-web-development-consultation','_blank')
              }
            }
            disabled={selectedIndex===null}
          >
            Speak to consultant
            
          </button>
          <button
            className="btn-main  rounded-md m-2 p-2 onb-footer-complete-btn border-radius-2"
            onClick={() => window.location.replace('/app/updated-dashboard')}
            disabled={selectedIndex===null}
          >
            Back to dashboard
            {/* {customButtonName ? customButtonName : "Continue"} */}
          </button>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default OnboardingPayment;
